import { render, staticRenderFns } from "./menu-option.vue?vue&type=template&id=8ca17442&scoped=true&"
import script from "./menu-option.vue?vue&type=script&lang=ts&"
export * from "./menu-option.vue?vue&type=script&lang=ts&"
import style0 from "./menu-option.vue?vue&type=style&index=0&id=8ca17442&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ca17442",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListItem,VListItemContent,VListItemIcon,VListItemTitle})
